import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitness Engineers and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Varun Popli',
    status : 'SOFTWARE ENGINEER'
  },
  {
    image: image2,
    review: 'Exceptional knowledge and dedication, they have been instrumental in achieving my fitness goals',
    name: 'Suny Sharma',
    status: 'ATHLETE'
  },
  {
    image : image3,
    review:'Their personalized approach transformed my perspective on fitness, making every session enjoyable and effective',
    name: 'Kartikey Mishra',
    status: "SOFTWARE ENGINEER"
  }
];
